(function () {
    'use strict';

    angular
        .module('vobeApp')
        .controller('PaymentTransactionsDialogItemController', PaymentTransactionsDialogItemController);


    function PaymentTransactionsDialogItemController($window, $scope, $rootScope, $state, $stateParams, $anchorScroll, $timeout, $interval, PaymentProcessTransaction, SnapScanRefreshTransaction, $httpParamSerializer, $sce, PaymentRedirectSingleton, Analytics, TagManager, GoogleAdwordsService, StripeElements, $element) {
        'ngInject';
        var transactionVM = this;
        var transactionStatusCheckMillis = 3000;


        //console.log("PaymentRedirectSingleton busy ? "+PaymentRedirectSingleton.isBusy());

        transactionVM.$onInit = function () {
            console.log('$onInit PaymentTransactionsDialogItemController', transactionVM.transaction)

            $anchorScroll();

            transactionVM.date = new Date();

            transactionVM.shoppingCart = $scope.vm.shoppingCart;
            transactionVM.transaction = $scope.transaction;
            transactionVM.propertyConfig = $scope.vm.propertyConfig;
            transactionVM.shoppingCartItems = [];
            transactionVM.method = 'POST';
            transactionVM.checkoutFormLoaded = false;
            transactionVM.finterpringHiddenIframe = false;
            transactionVM.backToPayment = backToPayment;
            transactionVM.fingerprintIframeSource = "";

            transactionVM.showSpinner = false;

            transactionVM.error;

            transactionVM.channel = $stateParams.channel;

            transactionVM.snapScan = {
                enabled: false,
                qrCode: ''
            }


            angular.forEach(transactionVM.shoppingCart.shoppingCartItems.items, function (item, index) {
                if (transactionVM.transaction.shoppingCartItemUUIDs.indexOf(item.uuid) > -1) {
                    transactionVM.shoppingCartItems.push(item);
                }
            });


            if (transactionVM.transaction.paymentTransactionStatusType === 'WAIT_REDIRECT_REPLY') {
                transactionVM.showSpinner = true;
                console.log('processPayment ... WAIT_REDIRECT_REPLY')
                processPayment();//it will do nothing, just keep calling endpoint and executing processTransactionCallback
            }

            if (transactionVM.transaction.paymentTransactionStatusType === 'INITIAL') {
                transactionVM.showSpinner = true;
                console.log('processPayment ... INITIAL')
                processPayment(); //process transaction straight away in the background
            }

            if (transactionVM.transaction.paymentTransactionStatusType === 'REDIRECT') {
                transactionVM.showSpinner = true;
                //process transaction straight away in the background
                processRedirect(transactionVM.transaction.paymentTransactionStatusAdditionalFields['REDIRECT']);
            }

            if (transactionVM.transaction.paymentTransactionStatusType === 'DISPLAY_IFRAME') {
                console.log('handle DISPLAY_IFRAME...')
                transactionVM.redirectObjectUrl = transactionVM.transaction.paymentTransactionStatusAdditionalFields['REDIRECT'].redirectURL;
            }

            if (transactionVM.transaction.paymentTransactionStatusType === 'DISPLAY_HTML') {
                console.log('DISPLAY_HTML', transactionVM.transaction.paymentAccountTypeId)
                transactionVM.showSpinner = true;
                //do nothing for now...
                if (transactionVM.transaction.paymentAccountTypeId === 23) {
                    initZapper(transactionVM.transaction.paymentTransactionStatusAdditionalFields.REDIRECT.redirectParams);
                } else if (transactionVM.transaction.paymentAccountTypeId === 24) {
                    initSnapScan(transactionVM.transaction.paymentTransactionStatusAdditionalFields.REDIRECT.redirectParams);
                }

            }

            if (transactionVM.transaction.paymentTransactionStatusType === 'THREEDS2FINGERPRINT' || transactionVM.transaction.paymentTransactionStatusType === 'THREEDS2CHALLENGE') {
                console.log('$onInit THREEDS2FINGERPRINT/THREEDS2CHALLENGE...', transactionVM.propertyConfig.paymentType, transactionVM.transaction.paymentTransactionStatusType)
                //adyen
                if (transactionVM.propertyConfig.paymentType === '25') {
                    var transactionParams = {
                        type: transactionVM.transaction.paymentTransactionStatusAdditionalFields[transactionVM.transaction.paymentTransactionStatusType].redirectParams.type,
                        paymentData: transactionVM.transaction.paymentTransactionStatusAdditionalFields[transactionVM.transaction.paymentTransactionStatusType].redirectParams.paymentData,
                        paymentMethodType: transactionVM.transaction.paymentTransactionStatusAdditionalFields[transactionVM.transaction.paymentTransactionStatusType].redirectParams.paymentMethodType,
                        token: transactionVM.transaction.paymentTransactionStatusAdditionalFields[transactionVM.transaction.paymentTransactionStatusType].redirectParams.token
                    }
                    handleAdyenCheckout(transactionParams, transactionVM.transaction.paymentTransactionStatusType === 'THREEDS2FINGERPRINT' ? 1 : 0);
                } else if (transactionVM.propertyConfig.paymentType === '11') {
                    //authipay SCA
                    //transactionVM.fingerprintIframeSource = transactionVM.transaction.redirectParams[]
                    console.log('-=-=-=-=-')
                    console.log('authipay $onInit THREEDS2FINGERPRINT...', transactionVM.transaction);
                    handleAuthipaySecure3DMethodForm(transactionVM.transaction.paymentTransactionStatusAdditionalFields[transactionVM.transaction.paymentTransactionStatusType])
                    console.log('-=-=-=-=-')
                } else if (transactionVM.propertyConfig.paymentType === '26') {
                    console.log('Global Pay / Elavon '+transactionVM.transaction.paymentTransactionStatusType);
                    transactionVM.finterpringHiddenIframe = true;
                    if (transactionVM.transaction.paymentTransactionStatusType === 'THREEDS2CHALLENGE') {
                        console.log('Global Pay / Elavon THREEDS2CHALLENGE...');
                        handleChallengeRequest(transactionVM.transaction.paymentTransactionStatusAdditionalFields[transactionVM.transaction.paymentTransactionStatusType]);
                    } else {
                        console.log('Global Pay / Elavon THREEDS2FINGERPRINT...');
                        handleFinegrprintHiddenIframe(transactionVM.transaction.paymentTransactionStatusAdditionalFields[transactionVM.transaction.paymentTransactionStatusType]);
                    }

                } else if (transactionVM.propertyConfig.paymentType === '38') {
                    console.log('Network Payments finterprint/challenge proceed ... ');
                    if (transactionVM.transaction.paymentTransactionStatusType === 'THREEDS2CHALLENGE') {
                        handleChallengeRequest(transactionVM.transaction.paymentTransactionStatusAdditionalFields[transactionVM.transaction.paymentTransactionStatusType]);
                    } else {
                        verifyChallenge(transactionVM.transaction.uuid, {}, processTransactionCallback, processTransactionErrorCallback);
                    }
                } else if (transactionVM.propertyConfig.paymentType === '39') {
                    console.log('Opayo finterprint/challenge proceed ... ');
                    if (transactionVM.transaction.paymentTransactionStatusType === 'THREEDS2CHALLENGE') {
                        handleChallengeRequest(transactionVM.transaction.paymentTransactionStatusAdditionalFields[transactionVM.transaction.paymentTransactionStatusType]);
                    }
                }
            }
        }

        function handleFinegrprintHiddenIframe(response) {
            console.log('handleFinegrprintHiddenIframe', response)
            if (response.redirectParams.methodUrl) {
                var postParams = {methodUrl: response.redirectParams.methodUrl, methodData: response.redirectParams.encodedMethodData, enrolled: true};
                console.log(postParams)
                GlobalPayments.ThreeDSecure.handle3dsVersionCheck(postParams);
                //add constant status refresh...
                transactionVM.paymentStatusInterval = $interval(function () {
                    console.log('checking payment status... current status: ', response.paymentStatus)
                    checkPaymentStatus(transactionVM.transaction.uuid, response.paymentStatus);
                }, transactionStatusCheckMillis);
            } else {
                //no methodUrl provided via, proceed with `3DS Method Completion Indicator as Unavailable.`
                console.log('no methodUrl provided via, proceed with ...3DS Method Completion Indicator as Unavailable....')
                var payload = {
                    threeDsMethod: 'unavailable'
                }
                verifyChallenge(transactionVM.transaction.uuid, payload, processTransactionCallback, processTransactionErrorCallback);
            }
        }

        function handleChallengeRequest(response) {
            console.log('handleChallengeRequest...', response)
            transactionVM.redirectObjectUrl = $sce.trustAsResourceUrl(response.redirectURL);
            transactionVM.method = 'POST';
            transactionVM.redirectObject = {
                redirectParams: {
                    creq: response.redirectParams.creq
                }
            };

            $timeout(function () {
                console.log('$broadcasting SUBMIT_PAYMENT_FORM', transactionVM);
                $scope.$broadcast('SUBMIT_PAYMENT_FORM', {uuid: transactionVM.transaction.uuid});
            }, 2000);
        }

        function backToPayment() {
            console.log('backToPayment')
            $state.transitionTo("payment", $stateParams, {
                reload: 'site', inherit: true, notify: true
            });
        }

        function handleAuthipaySecure3DMethodForm(response) {
            console.log('=1=1=1=1=1')
            console.log('handleAuthipaySecure3DMethodForm', response)
            if (response.paymentStatus === 'THREEDS2CHALLENGE') {
                console.log('THREEDS2CHALLENGE...')
                transactionVM.redirectObjectUrl = $sce.trustAsResourceUrl(response.redirectURL);
                transactionVM.method = 'POST';
                transactionVM.redirectObject = {
                    redirectParams: {
                        creq: response.redirectParams.creq
                    }
                };

                $timeout(function () {
                    console.log('$broadcasting SUBMIT_PAYMENT_FORM', transactionVM);
                    $scope.$broadcast('SUBMIT_PAYMENT_FORM', {uuid: transactionVM.transaction.uuid});
                }, 2000);

            } else {
                transactionVM.fingerprintIframeSource = $sce.trustAsHtml(response.redirectParams.secure3DMethodForm);
                //add constant status refresh...
                transactionVM.paymentStatusInterval = $interval(function () {
                    console.log('checking payment status... current status: ', response.paymentStatus)
                    checkPaymentStatus(transactionVM.transaction.uuid, response.paymentStatus);
                }, 3000);
            }

            console.log('=1=1=1=1=1')
        }

        function handleAdyenCheckout(params, type) {

            function handleFingerprintChallengeResponse(state, component) {
                var payload = {
                    'threeds2.fingerprint': state.data.details['threeds2.fingerprint'],
                    'paymentData': state.data.paymentData
                }
                verifyChallenge(transactionVM.transaction.uuid, payload, processTransactionCallback, processTransactionErrorCallback);
            }

            function handleChallengeResponse(state, component) {
                console.log('data', state.data);
            }

            var configuration = {
                clientKey: 'test_AQ4OG6GQYFEYVPUAGAUIA3KRTYT6F5XS', // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
                locale: 'en-US',
                environment: 'test',
                onAdditionalDetails: type === 1 ? handleFingerprintChallengeResponse : handleChallengeResponse
            };

            var threeDSConfiguration = {
                size: '05'
            }

            var checkout = new AdyenCheckout(configuration);
            console.log('----', params)
            transactionVM.checkoutFormAction = checkout.createFromAction(params, threeDSConfiguration);
            transactionVM.mountAdyen = function () {
                console.log('mount adyen')
                transactionVM.checkoutFormAction.mount(type === 1 ? '#fingerprint-challenge' : '#threeds-challenge');
            }
            transactionVM.checkoutFormLoaded = true;

        }

        function processPayment(paymentReply) {
            console.log('processPayment...', paymentReply);
            processTransaction(transactionVM.transaction.uuid, processTransactionCallback, processTransactionErrorCallback, paymentReply);
        }


        function refreshSite() {
            console.log('refreshSite...')
            $state.transitionTo($state.current, $stateParams, {
                reload: 'site', inherit: false, notify: true
            });
        }

        function processTransactionCallback(response) {
            console.log('processTransactionCallback...', response.paymentStatus)
            //console.log(response);
            //we got successful response, we expect it to hold new transaction status
            if (response && response.paymentStatus) {
                transactionVM.transaction.paymentTransactionStatusType = response.paymentStatus;
            }
            if (response && response.persistenceStatus) {
                transactionVM.transaction.persistenceTransactionStatusType = response.persistenceStatus;
            }

            //the success response is expected to be of 2 types: 1. redirect or 2. success
            if (response && response.paymentStatus === 'REDIRECT') {
                processRedirect(response)
            } else if (response && (response.paymentStatus === 'THREEDS2FINGERPRINT' || response.paymentStatus === 'THREEDS2CHALLENGE')) {
                var transactionParams = response.redirectParams;
                console.log('THREEDS2FINGERPRINT/THREEDS2CHALLENGE...', transactionVM.propertyConfig.paymentType)
                if (transactionVM.propertyConfig.paymentType === '25') {
                    var transactionParams = response.redirectParams;
                    handleAdyenCheckout(transactionParams, response.paymentStatus === 'THREEDS2FINGERPRINT' ? 1 : 0);
                } else if (transactionVM.propertyConfig.paymentType === '11') {
                    console.log('transactionParams', response.redirectParams)
                    handleAuthipaySecure3DMethodForm(response);
                } else if (transactionVM.propertyConfig.paymentType === '26') {
                    console.log('Global Payments API', transactionVM.transaction)
                    transactionVM.finterpringHiddenIframe = true;
                    if (transactionVM.transaction.paymentTransactionStatusType === 'THREEDS2CHALLENGE') {
                        console.log('Global Payments API handleChallengeRequest')
                        handleChallengeRequest(response);
                    } else {
                        console.log('Global Payments API handleFinegrprintHiddenIframe')
                        handleFinegrprintHiddenIframe(response);
                    }

                } else if (transactionVM.propertyConfig.paymentType === '38') {
                    console.log('-- NI Direct 38 --', response)
                    if (transactionVM.transaction.paymentTransactionStatusType === 'THREEDS2CHALLENGE') {
                        console.log('NI Direct handleChallengeRequest')
                        handleChallengeRequest(response);
                    } else {
                        console.log('NI Direct handleFinegrprintHiddenIframe')
                        verifyChallenge(transactionVM.transaction.uuid, {}, processTransactionCallback, processTransactionErrorCallback);
                    }
                } else if (transactionVM.propertyConfig.paymentType === '39') {
                    if (transactionVM.transaction.paymentTransactionStatusType === 'THREEDS2CHALLENGE') {
                        console.log('Opayo handleChallengeRequest')
                        handleChallengeRequest(response);
                    }
                }
            } else if (response && response.paymentStatus === 'WAIT_REDIRECT_REPLY') {
                transactionVM.showSpinner = true;
            } else if (response && response.paymentStatus === 'DISPLAY_HTML') {
                transactionVM.showSpinner = true;
                console.log('DISPLAY_HTML', response)
                if (response.paymentAccountType === 23) {
                    initZapper(response.redirectParams)
                } else if (response.paymentAccountType === 24) {
                    console.log('snapscan...', response);
                    initSnapScan(response.redirectParams);
                }
            } else if (response && response.paymentStatus === 'DISPLAY_IFRAME') {
                console.log('handle DISPLAY_IFRAME...', response)
                transactionVM.redirectObjectUrl = response.redirectURL;
                transactionVM.showSpinner = false;
            } else if (response && response.paymentStatus === 'COMPLETED') {
                //check paymentStatus for COMPLETED and SAVED
                checkPaymentStatus(transactionVM.transaction.uuid, response.paymentStatus);
            } else {
                transactionVM.showSpinner = false;
            }
        }

        function processTransactionErrorCallback(error) {
            console.log('processTransactionErrorCallback...')
            transactionVM.showSpinner = false;
            //we got transaction error, try to display required error information instead of status based on response headers TODO
            //console.log('transaction error');
            //console.log(error);
            if (error && error.data && error.data.paymentStatus) {
                //this is expected error somewhere is the flow
                transactionVM.transaction.paymentTransactionStatusType = error.data.paymentStatus;

            }
            if (error && error.data && error.data.persistenceStatus) {
                //this is expected error somewhere is the flow
                transactionVM.transaction.persistenceTransactionStatusType = error.data.persistenceStatus;

            }
            if (error && error.data && error.data.lastInternalResponseStatusDescription) {
                transactionVM.transaction.errorPaymentStatusDetails = transactionVM.transaction.errorPaymentStatusDetails || {};
                transactionVM.transaction.errorPaymentStatusDetails.message = error.data.lastInternalResponseStatusDescription;

            }


            if (error.status && error.status >= 500) {
                transactionVM.transaction.paymentTransactionStatusType = 'ERROR';
                transactionVM.transaction.persistenceTransactionStatusType = 'ERROR';
                //this is app internal error
                transactionVM.error = {
                    status: error.status,
                    message: error.data.message
                };
            } else {
                //unexpected error
                transactionVM.transaction.paymentTransactionStatusType = 'ERROR';
                transactionVM.transaction.persistenceTransactionStatusType = 'ERROR';
            }

        };

        function verifyChallenge(uuid, payload, callback, errCallback) {
            console.log('verifyChallenge...')
            var cb = callback || angular.noop;
            var cbe = errCallback || angular.noop;

            //call the endpoint to process transaction by uuid
            return PaymentProcessTransaction.service.process({uuid: uuid}, payload,
                function (response) {
                    return cb(response);
                },
                function (err) {
                    return cbe(err);
                }.bind(this)).$promise;
        }

        function processTransaction(uuid, callback, errCallback, paymentReply) {
            console.log('processTransaction', uuid)
            var cb = callback || angular.noop;
            var cbe = errCallback || angular.noop;

            //call the endpoint to process transaction by uuid
            return PaymentProcessTransaction.service.process({uuid: uuid, paymentReply: paymentReply},
                function (response) {
                    return cb(response);
                },
                function (err) {
                    return cbe(err);
                }.bind(this)).$promise;
        };

        function handleStripeConfirmPayment(clientSecret, returnUrl) {
            const stripe = Stripe(transactionVM.propertyConfig.stripePublicKey, {apiVersion: '2022-11-15'});

            return stripe.confirmPayment({
                clientSecret,
                confirmParams: {
                    return_url: returnUrl,
                },
            })
                .then(result => {
                    const error = result.error;
                    if (error) {
                        processPayment(true);
                    }
                });
        }

        function processRedirect(redirectObject) {

            console.log('processRedirect', transactionVM.transaction.paymentAccountTypeId);

            if (redirectObject.paymentStatus === 'REDIRECT') {

                transactionVM.redirectObject = redirectObject;
                transactionVM.redirectObjectUrl = $sce.trustAsResourceUrl(transactionVM.redirectObject.redirectURL);

                console.log('transactionVM.redirectObject.redirectURL', transactionVM.redirectObject.redirectURL)
                console.log('StripeElements.isActive()', StripeElements.isActive());

                if (StripeElements.isActive()) {
                    console.log('stripe applied...')
                    transactionVM.method = 'GET'; // change to get because ... Stripe...
                    // then parse the url to get client source secret...
                    handleStripeConfirmPayment(redirectObject.redirectParams.PaReq, redirectObject.redirectParams.TermUrl);
                    console.log('end processRedirect', redirectObject)

                    return;
                } else if (transactionVM.transaction.paymentAccountTypeId === 30 || transactionVM.transaction.paymentAccountTypeId === 31 || transactionVM.transaction.paymentAccountTypeId === 34 || transactionVM.transaction.paymentAccountTypeId === 35) {
                    //networkpayments/ngenius/spotii/binance requires GET.
                    transactionVM.method = 'GET';
                    var queryParams = getAllUrlParams(transactionVM.redirectObject.redirectURL);
                    transactionVM.redirectObject.redirectParams = queryParams;
                } else if (transactionVM.transaction.paymentAccountTypeId === 33) {
                    transactionVM.method = 'GET';
                } else {
                    transactionVM.method = 'POST';
                }

                console.log('$broadcasting SUBMIT_PAYMENT_FORM');
                $timeout(function () {
                    //console.log('$broadcasting SUBMIT_PAYMENT_FORM', transactionVM);
                    $scope.$broadcast('SUBMIT_PAYMENT_FORM', {uuid: transactionVM.transaction.uuid});
                }, 2000);
                //console.log('transactionVM: ', transactionVM);

            }

        };

        function getAllUrlParams(url) {
            console.log('getAllUrlParams', url)

            // get query string from url (optional) or window
            var queryString = url.split('?')[1];

            // we'll store the parameters here
            var obj = {};

            // if query string exists
            if (queryString) {

                // stuff after # is not part of query string, so get rid of it
                queryString = queryString.split('#')[0];

                // split our query string into its component parts
                var arr = queryString.split('&');

                for (var i = 0; i < arr.length; i++) {
                    // separate the keys and the values
                    var a = arr[i].split('=');

                    // in case params look like: list[]=thing1&list[]=thing2
                    var paramNum = undefined;
                    var paramName = a[0].replace(/\[\d*\]/, function (v) {
                        paramNum = v.slice(1, -1);
                        return '';
                    });

                    // set parameter value (use 'true' if empty)
                    var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

                    // if parameter name already exists
                    if (obj[paramName]) {
                        // convert value to array (if still string)
                        if (typeof obj[paramName] === 'string') {
                            obj[paramName] = [obj[paramName]];
                        }
                        // if no array index number specified...
                        if (typeof paramNum === 'undefined') {
                            // put the value on the end of the array
                            obj[paramName].push(paramValue);
                        }
                        // if array index number specified...
                        else {
                            // put the value at that index number
                            obj[paramName][paramNum] = paramValue;
                        }
                    }
                    // if param name doesn't exist yet, set it
                    else {
                        obj[paramName] = paramValue;
                    }
                }
            }

            return obj;
        }

        function initSnapScan(params) {
            console.log('snapscan.. init.', params)
            transactionVM.snapScan.qrCode = 'https://pos.snapscan.io/qr/' + params.merchantId + '.svg?id=' + params.reference + '&amount=' + (params.amount * 100) + '&snap_code_size=175&strict=true'
            transactionVM.snapScan.url = 'https://pos.snapscan.io/qr/' + params.merchantId + '?id=' + params.reference + '&amount=' + params.amount
            transactionVM.snapScan.enabled = true;
            transactionVM.transactionId = params.reference;
            transactionVM.showSpinner = false;

            transactionVM.snapScanInterval = $interval(function () {
                checkSnapScan();
            }, transactionStatusCheckMillis);
        }

        transactionVM.checkSnapScanButton = function () {
            console.log('checkSnapScanButton');
            transactionVM.snapScanIntervalButtonLoading = true;
            checkSnapScan()
            $timeout(function () {
                transactionVM.snapScanIntervalButtonLoading = false;
            }, 2000);
        }

        function checkPaymentStatus(uuid, paymentStatus) {
            PaymentProcessTransaction.status.get({uuid: uuid},
                function (response) {
                    console.log('response', response.paymentStatus)
                    if (response.paymentStatus === 'COMPLETED' && response.persistenceStatus === 'SAVED') {
                        $interval.cancel(transactionVM.paymentStatusInterval);
                        transactionVM.transaction.paymentTransactionStatusType = response.paymentStatus;
                        transactionVM.transaction.persistenceTransactionStatusType = response.persistenceStatus;
                    } else if (response.paymentStatus !== paymentStatus && response.paymentStatus !== 'COMPLETED' && response.paymentStatus !== 'ERROR') {
                         //stop interval first and then process
                         $interval.cancel(transactionVM.paymentStatusInterval);
                         processTransactionCallback(response);
                    } else if (response.paymentStatus === 'ERROR') {
                        //stop interval first and then process
                        $interval.cancel(transactionVM.paymentStatusInterval);
                        var callbackObject = {
                            data: response
                        }
                        processTransactionErrorCallback(callbackObject);
                    }
                },
                function (error) {
                    console.log('error occured, stopping paymentStatusInterval', error)
                    $interval.cancel(transactionVM.paymentStatusInterval);
                    $window.location.reload(); //lets reload the page
                })
        }

        function checkSnapScan() {
            console.log('checking snapscan transaction status...')
            SnapScanRefreshTransaction.refresh({transactionId: transactionVM.transactionId},
                function (response) {
                    console.log('response', response)
                    if (response.completed) {
                        //stop interval first and then process
                        $interval.cancel(transactionVM.snapScanInterval);
                        processPayment(true);
                    }
                },
                function (error) {
                    console.log('error occured, stopping interval', error)
                    $interval.cancel(transactionVM.snapScanInterval);
                    $window.location.reload(); //lets reload the page
                })
        }

        transactionVM.$onDestroy = function () {
            console.log('$onDestroy...')
            //before we leave, make sure this is stopped
            if (transactionVM.snapScanInterval) {
                $interval.cancel(transactionVM.snapScanInterval);
            }
        }

        function initZapper(params) {
            console.log('init zapper!', params);
            if (zapper) {
                console.log('zapper loaded!')
                $timeout(function () {
                    transactionVM.paymentWidget = new zapper.payments.PaymentWidget(
                        '.qr-payment-container',
                        {
                            merchantId: params.merchantId,
                            siteId: params.siteId,
                            amount: params.amount,
                            reference: params.reference
                        }
                    );
                    transactionVM.paymentWidget.on('payment', function (event) {
                        console.log('payment event', event.status);
                        if (event.status === 2) {
                            processPayment(true);
                        }
                    });
                    transactionVM.showSpinner = false;
                }, 1000);
            } else {
                console.log('zapper not loaded!')
            }
        }

        $window.onmessage = function (event) {
            if (typeof (event.data) !== 'undefined' && event.data.completed === true) {
                console.log('processPayment call onmessage...')
                processPayment();
            }
        };
    };


})();
