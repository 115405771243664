(function() {
    'use strict';

    angular
    .module('vobeApp')
    .controller('PaymentController', PaymentController);

    function PaymentController($scope, $rootScope, $state, $q, $transition$, previousState, $anchorScroll, CountriesService, CountriesWithoutPostcodeService, shoppingCart, PaymentAddCustomer, DetermineShopMoreState, $translate, Analytics, shoppingCartConfigs, currentProperty, currentPropertyGroup, StripeElements, stripe, availableCreditCards, availablePaymentOptions, $document, $uibModal, $window, CurrencyExchange, $uiRouterGlobals, propertyConfig, $timeout) {
        'ngInject';
	    var vm = this;
	    console.log('payment controller 1');

	    vm.$onInit = function() {

            $anchorScroll();

            vm.shoppingCart = shoppingCart;
            vm.isProcessing = false;
            vm.availablePaymentOptions = availablePaymentOptions;
            for (const availablePaymentOption of availablePaymentOptions) {
                if (availablePaymentOption.defaultPayment === true) {
                    vm.selectedPaymentOption = availablePaymentOption;
                    vm.selectedPaymentOptionId = availablePaymentOption.id;
                }
            }


            //currency
            vm.currentCurrency = {
                code : undefined
            }
            var currentCurrency = CurrencyExchange.currencies.current;//current currency might be NULL if this is group engine
            if (currentCurrency) {
                vm.currentCurrency.code = currentCurrency.code;
            }

            $rootScope.$on('CurrencyExchangeProvider.changeCurrency', function(event, args) {
                vm.currentCurrency.code = args.code;
            });

            var currenciesInCart = [];
            angular.forEach(shoppingCart.shoppingCartItems.items, function (cartItem, index) {
                if(!currenciesInCart.includes(cartItem.itemCurrency)) {
                    currenciesInCart.push(cartItem.itemCurrency);
                }
            });
            vm.itemCurrencies = currenciesInCart.join(',');
            vm.isSameCurrency = vm.currentCurrency && vm.currentCurrency.code ? currenciesInCart.includes(vm.currentCurrency.code) : false;

            trackCheckout();

            vm.browseMore = browseMore;


            vm.paymentFormInvalid = false;
            vm.startPayment = startPayment;
            vm.channel= $uiRouterGlobals.params.channel || '';

            vm.forceHidePromoBox = true;

            vm.selectCountry = [];
            vm.noPostcodeCountries = [];
            vm.isPostCodeRequired = true;
            vm.americanStates = states;

            loadCountries(loadCountriesCallback);
            loadCountriesWithoutPostcode(loadCountriesWithoutPostcodeCallback);


            var baseCard = {
                cardType: '',
                cardExpiryMonth: {value: 1},
                cardExpiryYear: {value: 2030},
                cardNumber: '',
                cvv: '',
                cardHolderName: '',
            };

            var baseCustomer = {
                title: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                town: '',
                country: '',
                county: '',
                postcode: '',
                address: '',
                newsletter: undefined,
                card: baseCard
            };

            vm.newsletter = {
                optin : false,
                optout : false
            };

            console.log("Customer from session: ",vm.shoppingCart.customer.newsletter);

            if(!vm.shoppingCart.customer || !vm.shoppingCart.customer.uuid || vm.shoppingCart.customer.uuid.length==0){
                console.log('extending customer with baseCustomer...')
                angular.extend(vm.shoppingCart.customer , baseCustomer);
            }

            vm.customer = vm.shoppingCart.customer;
            if (!vm.shoppingCart.customer.card) {
                vm.shoppingCart.customer.card = baseCard;
            }

            console.log("Customer after extending with base: ",vm.shoppingCart.customer.newsletter);
            vm.shoppingCartConfigs = shoppingCartConfigs;
            vm.currentProperty = currentProperty;
            vm.currentPropertyGroup = currentPropertyGroup;
            vm.property = currentProperty;
            var currentPropertyId = currentProperty.hotelId;
            vm.propertyConfig = propertyConfig;

            if (
                vm.shoppingCartConfigs &&
                vm.shoppingCartConfigs[currentPropertyId] &&
                vm.shoppingCartConfigs[currentPropertyId].configMap &&
                vm.shoppingCartConfigs[currentPropertyId].configMap.newsletterOptMode
            ) {
                if (vm.shoppingCartConfigs[currentPropertyId].configMap.newsletterOptMode == 1) {
                    vm.newsletter.optin = true;
                    vm.newsletter.optout = false;
                } else if (vm.shoppingCartConfigs[currentPropertyId].configMap.newsletterOptMode == 2) {
                    vm.newsletter.optin = false;
                    vm.newsletter.optout = true;
                }
            }

            //set vm.customer.newsletter property
            if (vm.newsletter.optin) {
                vm.newsletter.optout = false;
                //'newsletter' property on customer indicates opt-in flag, change it to be used in the checkbox as opt-in flag (same thing in this case)
                vm.customer.newsletter = vm.customer.newsletter == undefined ? false : vm.customer.newsletter; //f customer has no 'newsletter' property checkbox cannot be pre-ticked
            } else if (vm.newsletter.optout) {
                vm.newsletter.optin = false;
                //'newsletter' property on customer indicates opt-in flag, change it to be used in the checkbox as opt-out flag (reverse in this case)
                vm.customer.newsletter = vm.customer.newsletter == undefined ? false : !vm.customer.newsletter; //if customer has no 'newsletter' property checkbox cannot be pre-ticked
            } else {
                vm.newsletter.optin = false;
                vm.newsletter.optout = false;
                vm.customer.newsletter = false;
            }

            vm.stripeEnabled = false;
            vm.stripeError = {};
            vm.cardErrors = '';
            vm.stripeToken = '';
            vm.stripeRedirect = 'not_supported'; // default value for stripe sources
            vm.enableCardName = true; //this is used to hide card name field for stripe

            vm.paypal = vm.propertyConfig.paypal === 'true';

            vm.isRefundsContext = 'refunds' === $uiRouterGlobals.params.contextType;

            vm.displayCardPayment = true;
            vm.displayTerms = true;
            vm.displayCardExpiry = true;
            vm.displayCardCvv = true;

            vm.displayIdentityInput = false;
            if (vm.currentProperty.hotelCountryCode === 'CO' || vm.currentProperty.hotelCountryCode === 'BR') {
                vm.displayIdentityInput = true;
            }

            //by default use short form, unless:
            //1. we have postal vouchers
            //2. we have longFrm in config
            vm.longForm = false;
            angular.forEach(shoppingCart.shoppingCartItems.items, function (cartItem, index) {
                if (
                    cartItem.recipient.deliveryMethodType == 'post' &&
                    cartItem.recipient.deliveryPersonType == 'customer'
                ) {
                    vm.longForm = true;
                }
            });

            if (
                vm.shoppingCartConfigs &&
                vm.shoppingCartConfigs[currentPropertyId] &&
                vm.shoppingCartConfigs[currentPropertyId].configMap &&
                vm.shoppingCartConfigs[currentPropertyId].configMap.paymentType === '26' || vm.shoppingCartConfigs[currentPropertyId].configMap.paymentType === '33'
            ) {
                vm.longForm = true;
            }

            if (
                vm.shoppingCartConfigs &&
                vm.shoppingCartConfigs[currentPropertyId] &&
                vm.shoppingCartConfigs[currentPropertyId].configMap &&
                vm.shoppingCartConfigs[currentPropertyId].configMap.longForm
            ) {
                if (vm.shoppingCartConfigs[currentPropertyId].configMap.longForm == 'true') {
                    vm.longForm = true;
                }
            }

            vm.collapsePurchaserDetails = false;

            if (vm.isRefundsContext) {
                vm.longForm = false; //always short form
                vm.forceHidePromoBox = true;
                vm.isSameCurrency = true;
                vm.displayCardExpiry = false;
                vm.displayCardCvv = false;

                // insert dummy values
                vm.customer.card.cvv = "123";
                vm.customer.card.cardExpiryMonth = { value: 1 };
                vm.customer.card.cardExpiryYear = { value: 2028 };

                vm.displayTerms = false;
                vm.customer.termsAgreed = true;
                if ($transition$.params().customerRef && $transition$.params().customerRef == vm.customer.customerRef) {
                    vm.customer.emailConfirm = vm.customer.email;
                    //collapse customer only if it's valid
                    var customerValid =
                        vm.customer.firstName &&
                        vm.customer.firstName.length > 0 &&
                        vm.customer.lastName &&
                        vm.customer.lastName.length > 0 &&
                        vm.customer.email &&
                        vm.customer.email.length > 0 &&
                        vm.customer.emailConfirm &&
                        vm.customer.emailConfirm.length > 0 &&
                        vm.customer.phone &&
                        vm.customer.phone.length > 0 &&
                        vm.customer.country &&
                        vm.customer.country.length > 0 &&
                        (vm.longForm || (vm.customer.address && vm.customer.address.length > 0)) &&
                        (vm.longForm || (vm.customer.town && vm.customer.town.length > 0)) &&
                        (vm.longForm || (vm.customer.county && vm.customer.county.length > 0)) &&
                        (vm.longForm ||
                            !vm.isPostCodeRequired ||
                            (vm.customer.postcode && vm.customer.postcode.length > 0));

                    vm.collapsePurchaserDetails = customerValid;
                } else {
                    //if it's refunds context, we also do not need to enter the customer details, as it all will be the hotel itself
                    vm.collapsePurchaserDetails = true;
                    vm.customer.firstName = currentProperty.hotelName;
                    vm.customer.email = currentProperty.hotelEmail;
                    vm.customer.emailConfirm = currentProperty.hotelEmail;
                    vm.customer.phone = currentProperty.hotelPhone;
                    vm.customer.country = currentProperty.hotelCountry;
                }
            }

            if (vm.propertyConfig.paypal === 'true') {
                //temp values for expiry date.
                vm.customer.card.cardExpiryMonth = { value: 1 };
                vm.customer.card.cardExpiryYear = { value: 2028 };
                vm.displayCardPayment = false;
            }

            //red dot payment, don't display payment card options
            if (vm.propertyConfig.paymentType === '15') {
                vm.displayCardPayment = false;
                //temp values for expiry date.
                vm.customer.card.cardExpiryMonth = { value: 1 };
                vm.customer.card.cardExpiryYear = { value: 2028 };
            }

            var pgNoCardDetails = ['17', '19', '20', '25', '28', '30', '40'];
            if (pgNoCardDetails.includes(vm.propertyConfig.paymentType) && !vm.isRefundsContext && vm.availablePaymentOptions.length === 1) {
                vm.displayCardPayment = false;
                //temp values for expiry date.
                vm.customer.card.cardNumber = "4242424242424242";
                vm.customer.card.cvv = "123";
                vm.customer.card.cardType = "VISA";
                vm.customer.card.cardExpiryMonth = { value: 1 };
                vm.customer.card.cardExpiryYear = { value: 2028 };
            }

            //adyen
            vm.adyenPaymentMethods = {
                paymentMethods: [
                    {
                        brands: ['visa', 'mc', 'amex'],
                        details: [
                            {
                                key: 'encryptedCardNumber',
                                type: 'cardToken',
                            },
                            {
                                key: 'encryptedSecurityCode',
                                type: 'cardToken',
                            },
                            {
                                key: 'encryptedExpiryMonth',
                                type: 'cardToken',
                            },
                            {
                                key: 'encryptedExpiryYear',
                                type: 'cardToken',
                            },
                            {
                                key: 'holderName',
                                optional: true,
                                type: 'text',
                            },
                        ],
                        name: 'Credit Card',
                        type: 'scheme',
                    },
                ],
            };

            //gather browser info
            var now = new Date();
            vm.customer.browserInfo = {
                colorDepth: screen && screen.colorDepth,
                javaEnabled: navigator && navigator.javaEnabled(),
                javascriptEnabled: true,
                language: navigator && navigator.language,
                screenHeight: screen && screen.height,
                screenWidth: screen && screen.width,
                time: now,
                timeZoneOffset: now.getTimezoneOffset() / 60,
                userAgent: navigator && navigator.userAgent,
            };

            //adyen exceptions..
            function handleOnChange(state, component) {
                console.log('state', state, component);
                state.isValid; // True or false. Specifies if all the information that the shopper provided is valid.
                state.data; // Provides the data that you need to pass in the `/payments` call.
                component; // Provides the active component instance that called this event.
            }

            vm.adyenConfiguration = {};
            if (vm.propertyConfig.paymentType == 25 && vm.selectedPaymentOption.clientKey) {

                console.log('-=-=-=-=-=-=-=')
                console.log(vm.selectedPaymentOption.clientKey)

                var configuration = {
                    clientKey: vm.selectedPaymentOption.clientKey,
                    locale: navigator.language,
                    environment: vm.selectedPaymentOption.environment === 'test' ? 'test' : 'live',
                    showPayButton: false,
                    paymentMethodsResponse: vm.selectedPaymentOption.optionalPaymentMethods,
                    onChange: handleOnChange,
                    onValid: function (state) {
                        console.log('test onValid', state)
                        vm.customer.browserInfo = state.data.browserInfo;
                        vm.customer.encryptedCardNumber = state.data.paymentMethod.encryptedCardNumber;
                        vm.customer.encryptedExpiryMonth = state.data.paymentMethod.encryptedExpiryMonth;
                        vm.customer.encryptedExpiryYear = state.data.paymentMethod.encryptedExpiryYear;
                        vm.customer.encryptedSecurityCode = state.data.paymentMethod.encryptedSecurityCode;
                        vm.customer.encryptedCard = true;
                    },
                    onError: function(error) {
                        console.log(error);
                    },
                    allowPaymentMethods: ['scheme'],
                    paymentMethodsConfiguration: {
                        card: { // Example optional configuration for Cards
                            hasHolderName: true,
                            holderNameRequired: true,
                            enableStoreDetails: false,
                            hideCVC: false, // Change this to true to hide the CVC field for stored cards
                            onSubmit: function(){}, // onSubmit configuration for card payments. Overrides the global configuration.
                        }
                    }
                };

                vm.adyenConfiguration = configuration;

                // eslint-disable-next-line no-undef
                //var checkout = new AdyenCheckout(configuration);
                //checkout.create('dropin', {openFirstPaymentMethod: true}).mount('#dropin-container');
            }

            //for testing layouts
            //vm.displayCardPayment = false;
            //vm.collapsePurchaserDetails = true;


            //eventually decide on the size of purchaser details and payment layout parts
            vm.purchaserDetailsSize = 'full';
            vm.paymentDetailsSize = 'full';
            if (!vm.longForm) {
                //if it's long form both parts are half size smaller
                vm.purchaserDetailsSize = 'half';
                vm.paymentDetailsSize = 'half';
            }
            if (vm.collapsePurchaserDetails) {
                //if we don't display purchaser details, then payment part must take the full size
                vm.purchaserDetailsSize = 'full';
                vm.paymentDetailsSize = 'full';
            }
            if (!vm.displayCardPayment && vm.propertyConfig.paymentType != 25) {
                //if we don't display payment part, then purchaser details must take the full size
                vm.purchaserDetailsSize = 'full';
                vm.paymentDetailsSize = 'full';
            }

            //#####stripe logic start#######//
            var total = Object.values(vm.shoppingCart.shoppingCartItems.totalPerProperty).reduce((a, b) => a + b, 0);

            if (stripe && total > 0) {
                initStripeElements();//paymentElement.mount('#payment-element');
            }

            if (vm.propertyConfig.paymentType == 39) {
                initOpayoConfig();
            }

            vm.openBuyerDetailsModal = openBuyerDetailsModal;

            $scope.$watch('vm.customer.country', function (newValue, oldValue) {
                isPostCodeRequired(vm.selectedPaymentOption.clientKey);
            });

            sendDataLayerCheckoutStep();
        }

        function initOpayoConfig(clientKey) {
            console.log('================')
            console.log('opayo logic...', clientKey)
            console.log('================')
            var deferred = $q.defer();
            var script = document.createElement('script');
            script.type = 'text/javascript';
            document.getElementsByTagName('head')[0].appendChild(script);
            script.src = 'https://pi-live.sagepay.com/api/v1/js/sagepay.js';
            script.onload = function () {
                console.log('Opayo script load success')
                //deferred.resolve(StripeElements.initStripe(propertyConfig.stripePublicKey));
            }
            document.body.appendChild(script)
            //console.info('stripe enabled: ', propertyConfig.stripe, propertyConfig.stripePublicKey)
            return deferred.promise;
        }

        function createOpayoToken(clientKey) {
             // to prevent form submission
            console.log(vm.customer.card)
            var month = vm.customer.card.cardExpiryMonth.display;
            var year = vm.customer.card.cardExpiryYear.display.toString().slice(-2); // Extract last two digits of year
            var expiryDateInMMYY = month + year;

            sagepayOwnForm({merchantSessionKey: clientKey }).tokeniseCardDetails({
                cardDetails: {
                    cardholderName: vm.customer.card.cardHolderName,
                    cardNumber: vm.customer.card.cardNumber,
                    expiryDate: expiryDateInMMYY,
                    securityCode: vm.customer.card.cvv
                },
                onTokenised: function (result) {
                    if (result.success) {
                        console.log('token', result);
                        vm.customer.encryptedCardNumber = result.cardIdentifier;
                        addCustomer(goToPaymentTransactionsState);
                    } else {
                        console.error(JSON.stringify(result));
                        $timeout(function () {
                            vm.isProcessing = false;
                        }, 100);
                    }
                }
            });
        }

        function initStripeElements() {
            vm.stripeEnabled = true; //disables native form
            console.log('================')
            console.log('stripe logic...', currentProperty)
            console.log('================')

            var total = Object.values(vm.shoppingCart.shoppingCartItems.totalPerProperty).reduce((a, b) => a + b, 0);

            const options = {
                mode: 'payment',
                paymentMethodTypes: ['card'],
                paymentMethodCreation: 'manual',
                amount: total*100,
                currency: currentProperty.hotelCurrency.toLowerCase(),
                // Fully customizable with appearance API.
                appearance:{
                    variables: {
                        fontFamily: 'Open Sans, Helvetica Neue, Helvetica, Arial, system-ui, sans-serif'
                    }
                },
                fonts: [{
                    cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans'
                }]
            };

            // Set up Stripe.js and Elements to use in checkout form
            vm.elements = stripe.elements(options);

            // Create and mount the Payment Element
            vm.paymentElement = vm.elements.create('payment');
        }



        function handleCardChange(e) {
            vm.cardErrors = e.error ? e.error.message : '';
        }

        function createPaymentMethod(paymentElement) {
            const elements = vm.elements;
            vm.stripeError = {};

            return elements.submit()
                .then(({ error: submitError }) => {
                    if (submitError) {
                        return Promise.reject(submitError);
                    }

                    stripe.createPaymentMethod({
                        elements,
                        params: {
                            billing_details: {
                                name: !vm.enableCardName ? vm.card.cardHolderName : vm.customer.firstName + ' ' + vm.customer.lastName,
                                email: vm.customer.email,
                                phone: vm.customer.phone
                            }
                        }
                    }).then(({ error, paymentMethod }) => {
                        if (error) {
                            console.log('error', error);
                            return Promise.reject(error);
                        }

                        vm.stripeToken = paymentMethod.id;
                        addCustomer(goToPaymentTransactionsState);
                    })
                    .catch((error) => {
                        // Handle any errors that were caught in the promise chain
                        $timeout(function () {
                            vm.stripeError = error;
                        }, 100);
                    });
                })
                .catch((error) => {
                    // Handle any errors that were caught in the promise chain
                    $timeout(function () {
                        vm.stripeError = error;
                    }, 100);
                })
                .finally(() => {
                    $timeout(function () {
                        vm.isProcessing = false;
                    }, 100);
                });
        }

        function createSource() {
            console.log('createSource')
            stripe.createSource(vm.cardNumberElement, {
                owner: {
                    name: vm.customer.card.cardHolderName,
                }
            }).then(function (result) {
                if (result.error) {
                    // Inform the user if there was an error
                    console.log('error', result.error);
                } else {
                    console.log('result', result)
                    vm.stripeToken = result.source.id
                    vm.stripeRedirect = result.source.card.three_d_secure;
                    addCustomer(goToPaymentTransactionsState);
                }
            })
        }

        function createToken() {
            console.log('createToken');
            stripe.createToken(vm.cardNumberElement).then(function (result) {
                if (result.error) {
                    // Inform the user if there was an error
                    console.log('error', result.error);
                    return false;
                } else {
                    // Send the token to your server
                    vm.stripeToken = result.token.id;
                    addCustomer(goToPaymentTransactionsState);
                }
            });
        }

        //#####stripe logic end#######//

        /*
        this should be handled by transition error handler

        if (shoppingCart.shoppingCartPaymentTransactions.length > 0) {
            var allTransactionsCompleted = true;
            angular.forEach(shoppingCart.shoppingCartPaymentTransactions, function (t, index) {
                if (t.paymentTransactionStatusType != "COMPLETED") {
                    allTransactionsCompleted = false;
                }
            });

            console.log(allTransactionsCompleted);

            if (allTransactionsCompleted) {

            }
            else {
                //goToPaymentTransactionsState();
                //return;
            }
        }
        */

        function loadCountries(callback) {
            var cb = callback || angular.noop;

            CountriesService.get(function (response) {
                return cb(response);
            });
        }

        function loadCountriesWithoutPostcode(callback) {
            var cb = callback || angular.noop;

            CountriesWithoutPostcodeService.get(function (response) {
                return cb(response);
            });
        }

        function loadCountriesCallback(data) {
            //get countries from shopping basket and make them a priority
            var alreadyUsedCountryNames = [];

            var customerCountryAlreadyExists = false;
            if (typeof vm.customer.country === 'string' && vm.customer.country.length > 0) {
                alreadyUsedCountryNames.push(vm.customer.country);
                customerCountryAlreadyExists = true;
            }

            angular.forEach(shoppingCart.shoppingCartItems.items, function (item, index) {
                if (item.recipient.country && item.recipient.country.length > 0) {
                    if (customerCountryAlreadyExists) {
                        alreadyUsedCountryNames.push(item.recipient.country);
                    } else {
                        alreadyUsedCountryNames.unshift(item.recipient.country);
                    }
                }
                if (item.owningProperty.propertyCountry && item.owningProperty.propertyCountry.length > 0) {
                    alreadyUsedCountryNames.push(item.owningProperty.propertyCountry);
                }
            });

            //add priorities to countries
            angular.forEach(data.countries.country, function (c, index) {
                //add country priority
                var aIndex = alreadyUsedCountryNames.indexOf(c.countryName);
                var isPriority = aIndex > -1;
                var priorityIndex = aIndex - alreadyUsedCountryNames.length;
                if (isPriority) {
                    if (
                        typeof vm.customer.country === 'string' ||
                        priorityIndex < vm.customer.country.priorityCountryIndex
                    ) {
                        vm.customer.country = c;
                    }
                    var copy = angular.copy(c, {});
                    copy.priorityGroupIndex = 1; //like any other
                    copy.priorityCountryIndex = 1; //like any other
                    data.countries.country.push(copy);
                }
                c.priorityCountryIndex = isPriority ? priorityIndex : 1;
                c.priorityGroupIndex = isPriority ? priorityIndex : 1;
            });

            vm.selectCountry = data.countries.country;
        }

        function loadCountriesWithoutPostcodeCallback(data) {
            vm.noPostcodeCountries = data.countries.country;
            isPostCodeRequired();
        }

	    function isPostCodeRequired(){

	    	var isPostCodeRequired = true;
	    	if(typeof vm.customer.country === 'string' && vm.customer.country.length===0){
	    		isPostCodeRequired = false;
	    	}
	    	else if(typeof vm.customer.country === 'string' && vm.customer.country.length>0){
		    	angular.forEach(vm.noPostcodeCountries ,function(c, index){
		    		if(c[0]===vm.customer.country){
		    			isPostCodeRequired = false;
		    		}
		    	});
	    	}
	    	else {
		    	angular.forEach(vm.noPostcodeCountries ,function(c, index){
		    		if(c[0]===vm.customer.country.countryName){
		    			isPostCodeRequired = false;
		    		}
		    	});
	    	}

            vm.isPostCodeRequired = isPostCodeRequired;
        }

        $scope.$watch('vm.customer.country', function (newValue, oldValue) {
            isPostCodeRequired();
        });

        function startPayment(event) {
            vm.isProcessing = true;
            console.log('startPayment..!', vm.isProcessing)
            event.preventDefault();

            if (vm.paymentForm.$invalid) {
                console.log('vm.paymentForm.$invalid', vm.paymentForm.$invalid)
                var error = vm.paymentForm.$error;
                console.log(error)
                angular.forEach(error.required, function(field){
                    if(field.$invalid){
                        console.log(field.$name)
                    }
                });
                vm.paymentFormInvalid = true;
                vm.isProcessing = false;
                return;
            } else {
                vm.paymentFormInvalid = false;

                if (vm.stripeEnabled) {
                    createPaymentMethod(vm.paymentElement);
                } else if (vm.propertyConfig.paymentType === "39") {
                    createOpayoToken(vm.selectedPaymentOption.clientKey);
                } else {
                    addCustomer(goToPaymentTransactionsState);
                }
            }
        }

        function sendAddPaymentInfoDataLayer() {
            console.log('sendAddPaymentInfoDataLayer..!', vm.shoppingCart);
            var products = [];
            var overallRevenue = 0;
            angular.forEach(vm.shoppingCart.shoppingCartItems.items, function (cartItem, index) {
                products.push({
                    item_id: cartItem.itemId.toString(),
                    item_name: cartItem.itemName,
                    index: index+1,
                    price: cartItem.itemTotalPrice,
                    quantity: 1
                });
                overallRevenue = overallRevenue + cartItem.itemTotalPrice;
            });
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
                event: "add_payment_info",
                ecommerce: {
                    currency: vm.currentProperty.hotelCurrency,
                    value: overallRevenue,
                    payment_type: vm.selectedPaymentOption.displayName,
                    items: products
                }
            });
        }

        function goToPaymentTransactionsState(shoppingCartVM) {
            console.log('goToPaymentTransactionsState...');
            if (shoppingCartVM && shoppingCartVM.customer) {
                vm.shoppingCart.customer = shoppingCartVM.customer;
            }
            //send GA4 dataLayer info
            sendAddPaymentInfoDataLayer();

            $state.transitionTo('transactions', {}, {
                reload: 'site',
                inherit: true,
                notify: true
            });
        }

        function addCustomer(callback) {
            console.log('addCustomer...', vm.customer.card);
            var cb = callback || angular.noop;

            var ignoreCard = false;
            if (vm.selectedPaymentOption.type === 'token') {
                ignoreCard = true;
            }

            var customer = {
                title: vm.customer.title,
                firstName: vm.customer.firstName,
                lastName: vm.customer.lastName,
                email: vm.customer.email,
                phone: vm.customer.phone,
                identityNumber: vm.displayIdentityInput ? vm.customer.idType + ':' + vm.customer.idNumber : '',
                town: vm.customer.town,
                country: typeof vm.customer.country === 'string' ? vm.customer.country : vm.customer.country.countryName,
                countryCode: typeof vm.customer.country === 'string' ? vm.customer.country : vm.customer.country.countryCode,
                county: vm.customer.county,
                postcode: vm.customer.postcode,
                address: vm.customer.address,
                cardType: vm.customer.card.cardType,
                cardExpiryMonth: ignoreCard ? 1 : vm.customer.card.cardExpiryMonth.value,
                cardExpiryYear: ignoreCard ? 2033 : vm.customer.card.cardExpiryYear.value,
                cardNumber: ignoreCard ? '4242424242424242' : vm.customer.card.cardNumber,
                cvv: ignoreCard ? '999' : vm.customer.card.cvv,
                cardHolderName: vm.customer.card.cardHolderName,
                //below the opt-in or opt-out checkbox meaning is changed to customer 'newsletter' property with only opt-in meaning
                newsletter: (vm.customer.newsletter && vm.newsletter.optin) || (!vm.customer.newsletter && vm.newsletter.optout),
                stripeToken: vm.stripeToken,
                stripeRedirect: vm.stripeRedirect,
                customerRef: vm.customer.customerRef,
                vobeContext: $uiRouterGlobals.params.contextType,
                paymentAccountTypeId: vm.selectedPaymentOptionId,
                browserInfo: vm.customer.browserInfo,
                encryptedCardNumber: vm.customer.encryptedCardNumber,
                encryptedExpiryMonth: vm.customer.encryptedExpiryMonth,
                encryptedExpiryYear: vm.customer.encryptedExpiryYear,
                encryptedSecurityCode: ignoreCard ? vm.selectedPaymentOption.clientKey : vm.customer.encryptedSecurityCode, //send cliantKey for Opayo
                fulfillmentOptions: vm.customer.fulfillmentOptions,
                fulfillmentIdApplyOnce: vm.customer.fulfillmentIdApplyOnce,
            };

            return PaymentAddCustomer.add(
                customer,
                function (response) {
                    return cb(response);
                },
                function (err) {
                    console.error(err)
                    return cb(err);
                }.bind(this)
            ).$promise;
        }

        function browseMore(event) {
            event.preventDefault();
            var shopMoreState = DetermineShopMoreState.getShopMoreState();
            $state.go(shopMoreState.name, shopMoreState.params);
        }

        function openBuyerDetailsModal(size, parentSelector) {
            var defaultModalOptions = {
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'app/site/payment/modals/buyer-info.html',
                controller: 'PaymentInfoModalInstanceCtrl',
                controllerAs: '$ctrl',
                size: 'lg',
                appendTo: 'body',
                //resolve: {
                //customExplain: function () {
                //        return itemVM.customExplain;
                //    }
                //}
            };

            var parentElem = parentSelector ? angular.element($document[0].querySelector(parentSelector)) : undefined;
            var modalOptions = angular.extend({}, defaultModalOptions);
            modalOptions.templateUrl = 'app/site/payment/modals/buyer-info.html';
            modalOptions.appendTo = parentElem || modalOptions.appendTo;
            modalOptions.size = size || modalOptions.size;
            var modalInstance = $uibModal.open(modalOptions);
            //modalInstance.result.then(function (something) {
            //}, function () {
            //  console.info('Modal dismissed at: ' + new Date());
            //});
        }


        function trackCheckout() {
            angular.forEach(shoppingCart.shoppingCartItems.items, function (item, index) {
                Analytics.addProduct(
                    item.itemId, //productId
                    item.itemName, //name
                    item.itemType, //category
                    item.owningProperty.propertyCode, //brand
                    '', //variant
                    item.itemTotalPrice, //price
                    '1', //quantity
                    '', //coupon
                    '' //position
                );
            });
            Analytics.trackCheckout(2, 'Credit Card');
        }

        var sendDataLayerCheckoutStep = function () {
            var dataLayer = ($window.dataLayer = $window.dataLayer || []);

            var productsInCartGa4 = [];
            var overallRevenue = 0;
            angular.forEach(shoppingCart.shoppingCartItems.items, function (cartItem, index) {
                productsInCartGa4.push({
                    name: cartItem.itemName,
                    id: cartItem.itemId.toString(),
                    price: cartItem.itemTotalPrice,
                    brand: cartItem.owningProperty.propertyName,
                    category: cartItem.itemType,
                    quantity: 1,
                    affiliation: cartItem.owningProperty.propertyName,
                    item_list_id: cartItem.itemCategoryId ? cartItem.itemCategoryId : 1,
                    item_list_name: cartItem.itemCategoryName ? cartItem.itemCategoryName : 'All',
                });
                overallRevenue += cartItem.itemTotalPrice;
            });

            //ga4
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
                event: "begin_checkout",
                ecommerce: {
                    currency: vm.currentProperty.hotelCurrency,
                    value: overallRevenue,
                    items: productsInCartGa4
                }
            });

            console.log('=> trackBeginCheckout ...', dataLayer[dataLayer.length - 1]);
        };

        //function to update totals and totalsPerPropertyInCurrentCurrency from the shopping-cart-summary component
        vm.updateTotals = function (value) {
            console.log('updateTotals', value);
            vm.totals = value.totals;
            vm.totalsPerPropertyInCurrentCurrency = value.totalsPerPropertyInCurrentCurrency;
            vm.amountToCharge = 0;
            angular.forEach(vm.totalsPerPropertyInCurrentCurrency, function (value, key) {
                vm.amountToCharge += value.itemTotalPrice + value.totalShipping + value.serviceFee;
            });
        }

        vm.updateCard = function (value) {
            console.log('updateCard', value);
            vm.customer.card = value;
        }

        vm.changedPaymentOption = function(selectedPaymentOption) {
            console.log('changedPaymentOption',selectedPaymentOption )
            vm.selectedPaymentOption = selectedPaymentOption;
            angular.forEach(vm.availablePaymentOptions, function(value, key) {
                if (value.id === selectedPaymentOption.id && !value.displayCreditCard) {
                    //override baseCard values to dummy values.
                    vm.customer.card.cardNumber = "4242424242424242";
                    vm.customer.card.cvv = "123";
                    vm.customer.card.cardType = "VISA";
                    vm.customer.card.cardExpiryMonth = { value: 1 };
                    vm.customer.card.cardExpiryYear = { value: 2028 };
                } else {
                    vm.customer.card.cardNumber = ""; //default to empty.RedisVobeCacheConfiguration
                }

                //disable stripe elements if not selected

                vm.stripeEnabled = (selectedPaymentOption.id === 9)
                if (vm.stripeEnabled) {
                    initStripeElements();
                }
            })
        }

	}


	//credit card form component - NEW!
	angular.module('vobeApp')
        .component('creditCardForm', {
            templateUrl: 'app/site/payment/payment-credit-card.html',
            controller: 'CreditCardFormController',
            bindings: {
                paymentDetailsSize: '<',
                availableCreditCards: '<',
                formReference: '<',
                onUpdate: '&',
                card: '<',
                displayCardExpiry: '<',
                displayCardCvv: '<',
                paymentFormInvalid: '<',
                propertyConfig: '<',
            }
        });

    angular.module('vobeApp')
        .controller('CreditCardFormController', CreditCardFormController)

	function CreditCardFormController($scope, $translate) {
        'ngInject';

        var vm = this;
        vm.$onInit = function () {
            console.log('CreditCardFormController $onInit')
            var mmDisplay = 'MM';
            var yyDisplay = 'YY';

            $translate('payment.form.label.card.mm', {}, undefined, 'MM').then(function (translation) {
                mmDisplay = translation;
            });
            $translate('payment.form.label.card.yy', {}, undefined, 'YY').then(function (translation) {
                yyDisplay = translation;
            });

            vm.selectCardMonth = [
                {display: mmDisplay, value: '', disabled: true},
                {display: '01', value: 1},
                {display: '02', value: 2},
                {display: '03', value: 3},
                {display: '04', value: 4},
                {display: '05', value: 5},
                {display: '06', value: 6},
                {display: '07', value: 7},
                {display: '08', value: 8},
                {display: '09', value: 9},
                {display: '10', value: 10},
                {display: '11', value: 11},
                {display: '12', value: 12}
            ];
            vm.selectCardYear = [
                {display: yyDisplay, value: '', disabled: true}
            ];
            var currentYear = new Date().getFullYear();
            for (var y = currentYear; y < currentYear + 15; y++) {
                vm.selectCardYear.push({display: y, value: y});
            }

            vm.popoverUrl = {
                securityCode: 'app/site/payment/popovers/security-code-popover.html'
            };


            vm.card.cardExpiryMonth = vm.selectCardMonth[0];
            vm.card.cardExpiryYear = vm.selectCardYear[0];


            //update function to push back updated values to PaymentController
            vm.update = function (value) {
                console.log('running onUpdate..', value)
                var card = angular.extend({}, vm.card);
                  vm.onUpdate({value: card});
            };

        }
    }

    const states = [
        {
            "name": "Alabama",
            "abbreviation": "AL"
        },
        {
            "name": "Alaska",
            "abbreviation": "AK"
        },
        {
            "name": "American Samoa",
            "abbreviation": "AS"
        },
        {
            "name": "Arizona",
            "abbreviation": "AZ"
        },
        {
            "name": "Arkansas",
            "abbreviation": "AR"
        },
        {
            "name": "California",
            "abbreviation": "CA"
        },
        {
            "name": "Colorado",
            "abbreviation": "CO"
        },
        {
            "name": "Connecticut",
            "abbreviation": "CT"
        },
        {
            "name": "Delaware",
            "abbreviation": "DE"
        },
        {
            "name": "District Of Columbia",
            "abbreviation": "DC"
        },
        {
            "name": "Federated States Of Micronesia",
            "abbreviation": "FM"
        },
        {
            "name": "Florida",
            "abbreviation": "FL"
        },
        {
            "name": "Georgia",
            "abbreviation": "GA"
        },
        {
            "name": "Guam",
            "abbreviation": "GU"
        },
        {
            "name": "Hawaii",
            "abbreviation": "HI"
        },
        {
            "name": "Idaho",
            "abbreviation": "ID"
        },
        {
            "name": "Illinois",
            "abbreviation": "IL"
        },
        {
            "name": "Indiana",
            "abbreviation": "IN"
        },
        {
            "name": "Iowa",
            "abbreviation": "IA"
        },
        {
            "name": "Kansas",
            "abbreviation": "KS"
        },
        {
            "name": "Kentucky",
            "abbreviation": "KY"
        },
        {
            "name": "Louisiana",
            "abbreviation": "LA"
        },
        {
            "name": "Maine",
            "abbreviation": "ME"
        },
        {
            "name": "Marshall Islands",
            "abbreviation": "MH"
        },
        {
            "name": "Maryland",
            "abbreviation": "MD"
        },
        {
            "name": "Massachusetts",
            "abbreviation": "MA"
        },
        {
            "name": "Michigan",
            "abbreviation": "MI"
        },
        {
            "name": "Minnesota",
            "abbreviation": "MN"
        },
        {
            "name": "Mississippi",
            "abbreviation": "MS"
        },
        {
            "name": "Missouri",
            "abbreviation": "MO"
        },
        {
            "name": "Montana",
            "abbreviation": "MT"
        },
        {
            "name": "Nebraska",
            "abbreviation": "NE"
        },
        {
            "name": "Nevada",
            "abbreviation": "NV"
        },
        {
            "name": "New Hampshire",
            "abbreviation": "NH"
        },
        {
            "name": "New Jersey",
            "abbreviation": "NJ"
        },
        {
            "name": "New Mexico",
            "abbreviation": "NM"
        },
        {
            "name": "New York",
            "abbreviation": "NY"
        },
        {
            "name": "North Carolina",
            "abbreviation": "NC"
        },
        {
            "name": "North Dakota",
            "abbreviation": "ND"
        },
        {
            "name": "Northern Mariana Islands",
            "abbreviation": "MP"
        },
        {
            "name": "Ohio",
            "abbreviation": "OH"
        },
        {
            "name": "Oklahoma",
            "abbreviation": "OK"
        },
        {
            "name": "Oregon",
            "abbreviation": "OR"
        },
        {
            "name": "Palau",
            "abbreviation": "PW"
        },
        {
            "name": "Pennsylvania",
            "abbreviation": "PA"
        },
        {
            "name": "Puerto Rico",
            "abbreviation": "PR"
        },
        {
            "name": "Rhode Island",
            "abbreviation": "RI"
        },
        {
            "name": "South Carolina",
            "abbreviation": "SC"
        },
        {
            "name": "South Dakota",
            "abbreviation": "SD"
        },
        {
            "name": "Tennessee",
            "abbreviation": "TN"
        },
        {
            "name": "Texas",
            "abbreviation": "TX"
        },
        {
            "name": "Utah",
            "abbreviation": "UT"
        },
        {
            "name": "Vermont",
            "abbreviation": "VT"
        },
        {
            "name": "Virgin Islands",
            "abbreviation": "VI"
        },
        {
            "name": "Virginia",
            "abbreviation": "VA"
        },
        {
            "name": "Washington",
            "abbreviation": "WA"
        },
        {
            "name": "West Virginia",
            "abbreviation": "WV"
        },
        {
            "name": "Wisconsin",
            "abbreviation": "WI"
        },
        {
            "name": "Wyoming",
            "abbreviation": "WY"
        }
    ];

})();
